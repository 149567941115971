<template>
    <div :class="{'wait-cursor' : routing}">
          <ul class="nav flex-column nav-section">
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'home' }" href="" @click.prevent="navigate('home')">
                    <span :class="{ 'selected_icon' : route == 'home', 'nav_icon' : route != 'home'}">
                        <i v-show="!(route == 'home' && routing)" class="fas fa-tachometer-alt"></i>
                        <div v-show="(route == 'home') && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Home
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'classic' || route == 'product' }" href="" @click.prevent="navigate('classic')">
                    <span :class="{ 'selected_icon' : route == 'classic' || route == 'product', 'nav_icon' : route != 'classic'}">
                        <i v-show="!((route == 'classic' || route == 'product') && routing)" class="fas fa-crosshairs"></i>
                        <div v-show="(route == 'classic' || route == 'product') && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Classic
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'adam' || route == 'adamProduct' }" href="#" @click.prevent="navigate('adam')">
                    <span :class="{ 'selected_icon' : route == 'adam' || route == 'adamProduct', 'nav_icon' : route != 'adam'}">
                        <i v-show="!((route == 'adam' || route == 'adamProduct') && routing)" class="fas fa-robot"></i>
                        <div v-show="(route == 'adam' || route == 'adamProduct') && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Adam
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'tracker'}" href="#" @click.prevent="navigate('tracker')">
                    <span :class="{ 'selected_icon' : route == 'tracker', 'nav_icon' : route != 'tracker'}">
                        <i v-show="!(route == 'tracker' && routing)" class="fas fa-wave-square"></i>
                        <div v-show="route == 'tracker' && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Tracker
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'live'}" href="" @click.prevent="navigate('live')">
                    <span :class="{ 'selected_icon' : route == 'live', 'nav_icon' : route != 'live'}">
                        <i v-show="!(route == 'live' && routing)" class="fas fa-circle"></i>
                        <div v-show="route == 'live' && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Live
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'lucky'}" href="" @click.prevent="navigate('lucky')">
                    <span :class="{ 'selected_icon' : route == 'lucky', 'nav_icon' : route != 'lucky'}">
                        <i v-show="!(route == 'lucky' && routing)" class="fas fa-magic"></i>
                        <div v-show="route == 'lucky' && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Lucky
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'alerts'}" href="#" @click.prevent="navigate('alerts')">
                    <span :class="{ 'selected_icon' : route == 'alerts', 'nav_icon' : route != 'alerts'}" style="font-size:21px">
                        <i v-show="!(route == 'alerts' && routing)" class="fas fa-bell"></i>
                        <div v-show="route == 'alerts' && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Alerts
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'courses'}" href="#" @click.prevent="navigate('courses')">
                    <span :class="{ 'selected_icon' : route == 'courses', 'nav_icon' : route != 'courses'}">
                        <i v-show="!(route == 'courses' && routing)" class="fas fa-graduation-cap"></i>
                        <div v-show="route == 'courses' && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt Courses
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'ebooks'}" href="#" @click.prevent="navigate('ebooks')">
                    <span :class="{ 'selected_icon' : route == 'ebooks', 'nav_icon' : route != 'ebooks'}">
                        <i v-show="!(route == 'ebooks' && routing)" class="fas fa-book"></i>
                        <div v-show="route == 'ebooks' && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Ecomhunt E-Books
                </a>
            </li>
          </ul>
  
          <ul class="nav flex-column mb-2 mt-5">
              <li class="nav-item">
                  <a class="nav-link" :class="{ 'selected' : showResources}" href="#" @click.prevent="showResources = !showResources">
                      <span class="nav_icon"><i class="fas fa-layer-group"></i></span>
                      Resources
                      <span class="ml-5 pl-4" v-show="!showResources"><i class="fas fa-angle-down"></i></span>
                      <span class="ml-5 pl-4" v-show="showResources"><i class="fas fa-angle-up"></i></span>
                  </a>
              </li>
              
              <div class="subnav" :class="{ 'subnav-hide' : !this.showResources }">
                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="redirect('https://www.youtube.com/channel/UCl30RgwR4nKv9oej9oQ5n6A' , '_blank')">
                        <span class="nav_icon"><i class="fab fa-youtube"></i></span>
                        Youtube
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="redirect('https://blog.ecomhunt.com' , '_blank')">
                        <span class="nav_icon"><i class="fas fa-podcast"></i></span>
                        Ecomhunt Blog
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="redirect('https://ecomhunt.com/podcast' , '_blank')">
                        <span class="nav_icon"><i class="fas fa-podcast"></i></span>
                        Podcasts
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" @click.prevent="redirect('https://www.facebook.com/groups/ecomhunt/' , '_blank')">
                        <span class="nav_icon"><i class="fab fa-facebook-square"></i></span>
                        Community
                    </a>
                </li>
              </div>
              
              <li class="nav-item">
                <a class="nav-link" :class="{ 'selected' : route == 'support'}" href="#" @click.prevent="navigate('support')">
                    <span class="nav_icon" :class="{ 'selected_icon' : route == 'support', 'nav_icon' : route != 'support'}">
                        <i v-show="!(route == 'support' && routing)" class="fa-solid fa-comment-dots"></i>
                        <div v-show="route == 'support' && routing" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </span>
                    Support
                </a>
              </li>
          </ul>
      </div>
  </template>
  
  <script>
  export default {
    data() {
          return {
              showResources: false,
              route: this.$route.name,
              routing: false
          }
      },
      methods: {
          redirect: function (link, target = '_self') {
              window.open(link, target);
          },
          navigate(route){
              if (!this.routing) {
                  this.route = route
                  this.$router.push({ name: route }) 
              }
              
          }
      },
      directives: {
          hide: {
              bind: function(el, binding) {
                  console.log('a')
                  if (binding.value) {
                      console.log('b')
                      el.style.display = 'none';
                  } else {
                      console.log('c')
                      el.style.display = '';
                  }
              }
          }
      },
      created () {
          const resourceRoutes = []
          setTimeout(() => {
              if ( resourceRoutes.includes(this.$route.name) ){
                  this.showResources = true;
              }
          }, 1000);
  
          this.eventBus.$on('routed', () => {
              this.route = this.$route.name;
          });
  
          this.eventBus.$on('pageLoader', (value) => {
              this.routing = value;
          });
      },
  }
  </script>
  
  <style scoped>
  span.selected_icon {
      width: 34px;
      height: 34px;
      padding: 4px 8px;
      overflow: hidden;
      background-color: #56d500;
      display: inline-block;
      font-size: 18px;
      border-radius: 3px;
      margin: 0 18px 0 0;
  }
  
  .wait-cursor {
    cursor: wait !important;
  }
  .wait-cursor a {
    cursor: wait !important;
  }
  </style>>
  